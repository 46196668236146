.news-view-page {
  margin: 0 0 70px;



  .page-content {
    .cover-image{
      display: flex;
      height: 400px;
      overflow: hidden;
      position:relative;
      text-align: center;
      @include respond-down(small){
        text-align: left;
      }


      &:before{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(1,29,77,0.38);
        content: '';
        z-index: 20;
      }

      img{
        object-fit: cover;
        width: 100%;
        filter: blur(5px) grayscale(.45);
        -webkit-filter: blur(5px) grayscale(.45);
        -moz-filter: blur(5px) grayscale(.45);
        height: 100%;
      }

      @include respond-down(small){
        display: block;
        background-color: #2c2854;
        height: 300px;
      }

      .date{
        text-align: center;
        font-size: 25px;
        line-height: calc(19 / 16);
        position: relative;
        display: inline-block;
        @include respond-down(medium) {
          margin: 25px 0 0;
        }

        @include respond-down(small){
          font-size: 14px;
          margin-top: 10px;
        }

        &:after{
          position: absolute;
          left: 0;
          height: 3px;
          top: -7px;
          background: $orange;
          content: '';
          right: 0px;
          @include respond-down(small){
            height: 1px;
          }
        }
      }

      .cover-image-block{
        position: absolute;
        padding: 20px;
        color: $white;
        z-index: 21;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .page-title {
          font-weight: $bold;
          font-size: 50px;
          line-height: calc(37 / 30);
          color: $white;
          text-align: center;
          margin-bottom: 30px;
          @include respond-down(small){
            font-size: 20px;
            margin-bottom: 10px;
            text-align: left;
          }
        }
      }
    }

    .current {
      padding: 32px 30px 36px;
      background: $white;

      @include respond-down(small){
        padding: 20px 12px;
      }


      .images {

        .slick-arrow.slick-prev{
          left: -25px;
        }
        .slick-arrow.slick-next{
          right: -25px;
        }
        &.flat{
          display: flex;
          margin: 30px -10px 0px -10px;
          @include respond-down(small){
            display: block;
          }

          .image-wrapper{
            padding: 0 10px;

            .image{
              object-fit: cover;
            }
          }
        }

        .image-wrapper {
          display: flex;
          height: 270px;
          padding: 0 10px;

          @include respond-down(middle) {
            height: 230px;
          }

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include respond-down(small) {
              object-fit: cover;
            }
          }
        }

      }

      .date {
        margin: 30px 0 0;
        font-size: 16px;
        line-height: calc(19 / 16);
        color: #4A4A4A;
        position: relative;
        @include respond-down(medium) {
          margin: 25px 0 0;
        }




      }

      .text-content {
        margin: 20px 0 20px;
        @include respond-down(medium) {
          max-width: none;
        }

        ul{
          list-style: none;
          line-height: 1.4;

          li{
            &:before{
              content: '—';
              color: $orange;
            }
          }
        }

        a{
          text-decoration: underline;
          cursor: pointer;
          transition: $default-transition;
          &:hover{
            color: #ff9e00;
          }
        }

        .button{
          text-decoration: none;
          padding: 10px 35px;
          &:hover{
            color: #fff;
            background-color: #001E4D;
          }
        }

        &.two-columns {
          @include respond-up(middle) {
            max-width: none;
            column-count: 2;
            column-gap: 50px;
          }
        }

        &, p, * {
          font-size: 16px;
          line-height: calc(19 / 16);
        }

        &, p, *:not(a) {
          color: $black;
        }

        img {
          height: auto !important;
        }

        strong {
          border-left: 3px solid $orange;
          padding-left: 5px;
        }
      }
    }

    .prev-next {
      display: flex;
      position: relative;
      height: 30px;
      margin: 20px 0;

      a {
        position: absolute;

        span {
          @include textUnderline;
          font-weight: $bold;
          text-transform: uppercase;
        }

        .svg-icon {

          margin-top: -2px;
          border-bottom: none;
        }
      }

      .prev {
        left: 0;

        .svg-icon {
          margin-right: 20px;
        }
      }

      .next {
        right: 0;

        .svg-icon {
          transform: rotateZ(180deg);
          margin-left: 20px;
        }
      }
    }


    .news__items {
      .news__item {
        &:not(:last-child) {
          @include respond-down(medium) {
            margin-bottom: 20px;
          }
        }
      }
    }

    .other-news {
      margin: 70px 0 0;
      @include respond-down(middle) {
        margin: 35px 0 0;
      }
      h2{
        margin-bottom: 30px;
      }
    }

    .all-news {
      display: flex;
      justify-content: center;
      margin: 30px 0 0;

      @include respond-down(medium) {
        margin: 30px 0 0;
      }

      a {
        @include textUnderline;
        font-weight: $bold;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
