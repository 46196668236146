.vacancy {
    // .vacancy__title
    &__title {
        @extend .text-center;
        @extend .title-second;
        @extend .styled-header;
    }
    // .vacancy__row
    &__row {
        display: flex;
        max-width: 1260px;
        margin: 0 auto 100px;
        @media(max-width:899px) {
            flex-direction: column;
        }
    }
    // .vacancy__btn-body
    &__btn-body {
        @media(max-width:899px) {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 50px;
        }
        @media(min-width:900px) {
            flex: 0 0 300px;
            margin-right: 50px;
            height: 100%;
        }
    }
    // .vacancy__tab-body
    &__tab-body {
        transition: all 0.3s ease-in;
        @media(min-width:900px) {
            flex: 1 1 900px;
        }
    }
    // .vacancy__btn
    &__btn {
        @extend .new-btn;
        background: transparent;
        &_active {
            background: #F5B142;
        }
        &:hover {
            background: #fff;
        }
        @media(max-width:899px) {
            flex: 1 1 300px;
        }
    }
    // .vacancy__vacant
    &__vacant {

    }
    // .vacancy__name
    &__name {
        position: relative;
        display: flex;
        align-items: center;
        height: auto;
        min-height: 55px;
        margin-bottom: 25px;
        padding-right: 35px;
        padding-left: 25px;
        font-family: $proximaNova;
        font-size: 14px;
        line-height: calc(17 / 14 * 100%);
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #000;
        border: 2px solid #F5B142;
        cursor: pointer;
        &:last-child {
            margin-bottom: 0;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 6px;
            height: 100%;
            background: #F5B142;
            transition: all 0.3s ease-in;
        }
        &::after {
            // vacancy_arrow
            content: '';
            position: absolute;
            right: 0;
            width: 18px;
            height: 10px;
            margin-right: 10px;
            transform: rotate(180deg);
            transition: all 0.3s ease-in;
            background: no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjUxIDcuNTA1QzE2Ljg3NiA2Ljg1NyAxMC4yMjEuNTIgMTAuMjIxLjUyYTEuNzAyIDEuNzAyIDAgMDAtMi40NDUgMFMxLjEyMyA2Ljg1Ny40OSA3LjUwNWMtLjYzMi42NS0uNjc1IDEuODE1IDAgMi41MDcuNjc1LjY5MiAxLjYxOC43NDcgMi40NDYgMEw5IDQuMTk4bDYuMDY0IDUuODE0Yy44MjkuNzQ3IDEuNzcuNjkyIDIuNDQ1IDAgLjY3Ni0uNjkyLjYzMy0xLjg1OCAwLTIuNTA3eiIgZmlsbD0iI0Y1QjE0MiIvPjwvc3ZnPg==);
        }
        &_active {
            &::after {
                transform: rotate(0deg);
            }
        }
        &:hover {
            &::before {
                width: 10px;
            }
        }
    }
    // .vacancy__content
    &__content {
        display: none;
        div {
            margin-left: 20px;
            margin-bottom: 25px;
        }
        hr {
            margin-bottom: 20px;
            border-top: 2px solid #F5B142;
        }
        ul {

        }
        li, p {
            font-family: $proximaNova;
            font-size: 14px;
            line-height: calc(17 / 14 * 100%);
            letter-spacing: 0.05em;
            color: #000;
        }
        li {
            list-style: disc;
            margin-left: 20px;
        }
        p {
            margin-bottom: 25px;
            text-transform: uppercase;
        }
        &_active {
            display: block;
        }
    }
    // .vacancy__contacts
    &__contacts {
        padding: 30px 0 0 25px;
        margin-bottom: 40px;
        background: #161245;
    }
    // .vacancy__contacts-title
    &__contacts-title {
        margin-bottom: 30px;
        margin-right: 25px;
        font-family: $proximaNova;
        font-size: 18px;
        line-height: calc(22 / 18 * 100%);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #fff;
    }
    // .vacancy__contacts-info
    &__contacts-info {
        display: flex;
        flex-wrap: wrap;
    }
    // .vacancy__contacts-item
    &__contacts-item, &__contacts-description a {
        font-family: $proximaNova;
        font-size: 14px;
        line-height: calc(17 / 14 * 100%);
        letter-spacing: 0.05em;
        color:#fff;
    }
    // .vacancy__contacts-item
    &__contacts-item {
        flex: 1 1 auto;
        display: flex;
        margin-bottom: 30px;
        margin-right: 25px;
        img {
            margin-right: 15px;
        }
        @media(min-width:1000px) {
            flex: 0 1 250px;
        }
        &_tel, &_time {
            img {
                width: 18px;
                height: 18px;
            }
        }
        &_email {
            img {
                width: 16px;
                height: 12px;
            }
        }
    }
    // .vacancy__contacts-description
    &__contacts-description {
    }
    // .vacancy__text
    &__text {
    }
}
