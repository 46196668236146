.news-list-page {

  .page-title {
    @include respond-property(font-size, 20px, 20px, 25px, 30px, 30px, 30px);
    margin-bottom: 40px;
    font-weight: $bold;
    line-height: calc(37 / 30);
    color: $black;

  }

  .page-content {

    .news__items {
      .news__item {
        a:not(.news__read-more){
          display: block;
          width: 100%;
        }
        @include respond(small) {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .news__read-more {
          display: inline-block;
        }
      }
    }

    .exhibition__items {
      background-color: $white;
      padding: 30px;
      position: relative;

      &:not(.slick-initialized){
        height: 350px;
        overflow: hidden;
        &:before{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #001e4d69;
          content: '';
          z-index: 999999;
          filter: blur(10px);
        }
        .exhibition__title-block{
          opacity: 0;
          visibility: hidden;
        }
        .exhibition__image-wrapper{
          &:after{
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      @include respond-down(small){
        padding: 0px;
        padding-bottom: 20px;

        &.no-padding{
          padding-bottom: 0px;
        }
      }

      .slick-arrow {
        width: 16px;
        height: 32px;

        &.slick-prev {
          left: -30px;
          background-image: url("/static/svg/arrow-prev-383bc526ac936dd0553dbe7be3ec6f7f.svg");
          @include respond-down(small) {
            left: 5px;
          }
        }

        &.slick-next {
          right: -30px;
          background-image: url("/static/svg/arrow-prev-383bc526ac936dd0553dbe7be3ec6f7f.svg");
          transform: translateY(-50%) rotateZ(180deg);
          @include respond-down(small) {
            right: 5px;
          }
        }
      }

      .exhibition__item {
        position: relative;
        color: #ffffff;
        display: block;


        .exhibition__image-wrapper {

          display: flex;
          width: 100%;
          position: relative;

          &:after {
            content: '';
            width: 100%;
            max-height: 354px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            top: 0;
            background: rgba(1, 29, 77, 0.9);

          }

          .exhibition__image {
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
            margin-bottom: 20px;
            max-height: 350px;
            filter: blur(3px);
            @include respond-down(small) {
              filter: blur(4px);
              height: 300px;
              margin-bottom: 0px;
            }
          }
        }

        .exhibition__title-block {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          padding: 0px 20px;
          z-index: 1;

          .exhibition__title {
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            margin: 10px 0px 20px 0px;
            display: block;
            font-weight: 500;

            @include respond-down(small){
              font-size: 20px;
            }
          }

          .exhibition__description{
            margin-top: 30px;
            max-width: 850px;
            font-size: 19px;
            line-height: 1.7;
            @include respond-down(small){
              font-size: 14px;
              line-height: 1.3;
            }
          }

          .date {
            position: relative;
            padding-top: 5px;
            font-size: 16px;
            margin-top: 10px;
            &:after{
              position: absolute;
              left: 0;
              width: 50px;
              height: 2px;
              top: 0px;
              background: $orange;
              content: '';
            }
          }
        }
      }
    }

    .pagination-wrapper {
      margin: 0;
      @include respond-down(medium) {
        margin: 30px 0 0;
      }

      .pagination-block {
        .pagination-list {
          li {
            text-align: center;

            a {
              background: transparent;
              color: $orange;
              &.loading{
                .svg-icon{
                  animation-name: spin;
                  animation-duration: 1000ms;
                  animation-iteration-count: infinite;
                  animation-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
                }

              }
              .svg-icon {
                margin-right: 15px;
              }

              span {
                @include textUnderline;
                text-transform: uppercase;
                font-weight: $bold;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
