// Классы отдельной страницы
.industries-item-page {
    @extend .news-view-page;
    .page-content {
    }
    .cover-image {
    }
    .cover-image-block {
    }
    .page-title {
    }
    .article {
    }
    .block-index-wrapper {
        color: $black;
    }
    .page-title {
    }
    .no-styled {
    }
    .date {
    }
    .current {
        margin-bottom: 50px;
    }
    .text-content {
    }
    .using-equipment {
        // это очень странно работает
        @extend .industries-page, .list;
        .list {
            // .list__item
            &__item {
                flex-basis: calc(33.333% - 20px);
                @include respond-down(medium) {
                    flex-basis: calc(50% - 20px);
                }
                @include respond-down(small) {
                    flex: 0 0 100%;
                    max-width: 400px;
                    margin-right: 0;
                }
                &.industry {
                    &:hover {
                        background-color: $white;
                        .industry__title {
                            color: $blue;
                        }
                    }
                }
            }
            .industry__title {
                flex: 1 1 auto;
                margin-bottom: 6px;
                font-weight: 500;
                font-size: 16px;
                color: #000;
                line-height: 20px;
                text-transform: uppercase;
            }
            .industry__subtitle {
                font-size: 13px;
                color: #767676;
                text-align: center;
                line-height: 15px;
                background: #ffffff;
            }
            .industry__pic {
                flex: 0 1 auto;
            }
            .industry__button {
                .button {
                    width: 100%;
                }
            }
        }
        .icon {
        }
        .svg-icon {
        }
        .base {
        }
        .hover {
        }
        .button {
        }
        .bordered {
        }
    }
}
