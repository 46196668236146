.industries-page {
  & > .title {
    margin-bottom: 70px;
  }

  .paragraph {
    p {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 26px;

      @include respond-down(small) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    margin-right: -20px;
    overflow: hidden;
    @include respond-down(small) {
      justify-content: center;
      margin-right: 0;
    }

    &__item {
      min-height: 330px;
      flex-basis: calc(25% - 20px);
      margin-bottom: 20px;
      margin-right: 20px;
      background-color: #ffffff;
      text-decoration: none;

      @include respond-down(middle) {
        flex-basis: calc(33.333% - 20px);
      }
      @include respond-down(medium) {
        flex-basis: calc(50% - 20px);
      }

      @include respond-down(small) {
        flex: 0 0 100%;
        max-width: 400px;
        margin-right: 0;
      }
      &:hover {
        background-color: $blue;
      }
    }

    .industry {
      display: flex;
      flex-direction: column;
      padding: 20px 18px;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012d72eb;
        opacity: 0;
        visibility: hidden;
        transition: $default-transition;
        z-index: -1;
      }

      &__title {
        hyphens: auto;
        font-size: 21px;
        line-height: 21px;
        font-weight: bold;
        text-transform: uppercase;

        @include respond-down(large) {
          font-size: 18px;
        }

        @include respond-down(middle) {
          font-size: 16px;
        }

        @include respond-down(medium) {
          font-size: 21px;
        }
      }

      &__pic {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        padding: 10px 0;

        .hover {
          display: none;
          opacity: 0;
        }
      }

      &__button {


        .button {
          width: calc(100% - 78px);
          height: 52px;
          line-height: 50px;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
        }
      }

      &:hover {

        .industry__title {
          color: #ffffff;
        }

        .industry__pic {
          .base {
            opacity: 0;
            display: none;
          }

          .hover {
            opacity: 1;
            display: inline-block;
          }
        }

        .button {
          background: $orange;
          color: #fff;
        }
      }
    }
  }
}


@import 'item.scss';