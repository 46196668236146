.news_form {
  width: 50%;
  margin: 0px auto;

  label{
    display: none;
  }

  .policy{
    margin-top: 20px;
  }

    .news_form_agree{
        color: #000;
        margin-bottom: 10px;
        font-size: 14px;

        a{
            color: #2c2959;
            text-decoration: underline;
        }
    }

  @include respond-down(middle) {
    width: 100%;
  }

  button {
    padding: 10px 30px;
    margin: 0px auto;
  }

  .button_holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
  }

  .news_form_title {
    margin-bottom: 30px;
  }
  .form-field {
    margin-bottom: 30px;

    .errors {
      margin-left: 30px;
      margin-top: 15px;
    }

    .email-phone {
      background: rgb(236, 237, 236) !important;
    }

    .label {
      margin-left: 27px;
      margin-bottom: 10px;
    }

    select {
      color: #7b7b7b;
      font-size: 14px;
      padding-left: 23px;
    }
  }
}
