.switch-form-modal{


    &__header{
        color: #000;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        position: relative;
        margin-bottom: 30px !important;
        &::after{
            width: 30%;
            height: 4px;
            background-color: #F8AF04;
            position: absolute;
            content: '';
            bottom: -10px;
            left: 35%;
        }
    }
    &__half{
        display: flex;

        gap: 1rem;

        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        @include respond-up(medium){
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

    }
    &__form-group{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 1.4rem;
    }
    &__input{
        width: 100%;
    }
    &__button_wrap{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
    &__policy{
        width: 320px !important;
    }

    &__button{
        width: 180px !important;
    }

    // .form-footer__argee
    &__argee {
        width: 100%;
        position: relative;
        display: flex;

        color: #000;
        cursor: pointer;
        line-height: 1.2rem;

        font-size: 13px !important;

        font-style: normal;
        font-weight: 400;


        label{
            color: #000;
            line-height: 1.2rem;
            font-size: 13px !important;

        }
        a {
            color: #F5B142;
            transition: all 0.3s ease-in;
            font-size: 13px !important;
            &:hover {
                text-decoration: underline;
            }
        }

        span {
            //display: flex;
            //align-items: center;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 17px;
                height: 17px;
                color: #000;
                border: 1px solid #161245;
                border-radius: 50%;
            }
        }
        &._white {
            color: #fff;
            span {
                border-color: #fff;
                &::before {
                    border-color: #fff;
                }
            }
            .form-footer__checkbox:checked + span::after {
                background: #fff;
            }
        }
        &._center {
            align-items: center;
        }
    }
    // .form-footer__checkbox
    &__checkbox {
        appearance: none;
        width: 19px;
        height: 19px;
        margin: 0px 16px 0px 0px;
        &:checked + span {
            &::after {
                content: '';
                position: absolute;
                top: 3px;
                left: 3px;
                width: 13px;
                height: 13px;
                background: #161245;
                border-radius: 50%;
            }
        }
    }
}

.magnets_landing {

    margin-top: 75px;


    @include respond-up(large){
        margin-top: 113px;
    }

    #images-container{

        @include respond-up(large){
            .slick-dots{
                display: none !important;
            }
        }
    }


    select{
        color: #7c7c7c;
        padding: 0px 20px;
        background-color: #fff;
    }

    background-color: #E9EAE9;


    .specification-logos-slider{

        width: 100%;
        display: block;
        margin-top: 2.5rem;



        @include respond-up(large){
            display: none;
        }

        &__item{
            //width: 100%;
            //max-width: 200px;
            img{
                width: 90%;
                height: 2.5rem;
            }
        }
    }

    .index-block.consult{
        padding: 33px 0 44px;
    }

    .title-second{
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .sliders-wrapper{

        .main-slider{
            margin-bottom: 1rem;
            .slide-wrapper{
                background: white;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        .slick-slide img {
            margin: 0 auto;
            height: auto;
            width: auto;
            object-fit: contain;

            @include respond-up(medium){
                height: 450px;
            }

            &.nav-img{
                height: 180px;
                width: 100%;
                -o-object-fit: contain;
                object-fit: cover;
            }
        }

        .nav-slider{
            .slick-current{
                .nav-img{
                    //border: 4px solid #f5b142;
                    outline: 4px solid #f5b142;
                    outline-offset: -4px;
                }

            }
            .slide-wrapper{
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                background: white;

            }
        }


    }

    &__hero{
        background-image: url("/static/images/landing/magnets/hero_mobile.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin-bottom: 1.3rem;

        @include respond-up(medium){
            background-image: url("/static/images/landing/magnets/hero_tablet.jpg");
            background-position: center center;
        }

        @include respond-up(large){
            background-image: url("/static/images/landing/magnets/hero.jpg");
            background-position: bottom center;
        }
    }

    &__video{
        background-image: url("/static/images/landing/magnets/bg_mobile.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        padding: 5rem 0px;

        @include respond-up(medium){
            background-image: url("/static/images/landing/magnets/bg_tablet.jpg");
        }

        @include respond-up(large){
            background-image: url("/static/images/landing/magnets/bg.jpg");
        }
    }

    &__gallery{

        padding-bottom: 3.12rem;

        @include respond-up(large){
            padding-bottom: 0rem;
        }

    }

    &__request{
        margin: 0rem 0rem 3rem;

        @include respond-up(large){
            margin: 0rem 0rem 0rem;
            padding: 3.81rem 0rem;
        }
    }

    &__specification{
        margin: 7rem 0;

        @include respond-up(large){
            margin: 6.69rem 0 3.62rem;
        }


    }

    &__benefits{
        background-image: url("/static/images/landing/magnets/bg_silver_mobile.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        padding: 3.63rem 0px 0;

        @include respond-up(medium){
            background-image: url("/static/images/landing/magnets/bg_silver_tablet.png");
        }

        @include respond-up(large){
            background-image: url("/static/images/landing/magnets/bg_silver.png");
        }
    }

    &__contacts{
        margin-top: 0rem;
    }

    .hero{
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;

        justify-content: center;
        gap: 1.25rem;

        align-items: center;
        text-align: center;

        @include respond-up(medium){
            align-items: flex-start;
            text-align: left;
            gap: 1.87rem;
        }

        @include respond-up(large){
            height: 80vh;
        }

        &__header{
            color: #FFF;
            font-size: 2.1875rem;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-transform: uppercase;
        }

        &__text{
            color: #FFF;
            font-size: 1.5625rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
        }

        &__button{
            .button{
                padding: 0rem 3.8rem;
                font-size: 1.25rem;
                height: auto;
                border-radius: 4rem;
            }
        }
    }

    .video{

        .video-player{
            position: relative;

            display: block;
            border-radius: 1rem;

            margin: 0rem 0rem 0rem 0rem;


            @include respond-up(large){
                margin: 0rem 0rem 0rem 5rem;
            }

            &__play_wrap{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 1rem;
                background-color: rgba(0,0,0,0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
            }

            &__image{
                width: 100%;
                border-radius: 1rem;

            }

            &__iframe{

                &.active{
                    display: block;
                }

                width: 100%;
                aspect-ratio: 16/9;
                border-radius: 1rem;
                display: none;
            }

            &__play{
                width: 3.875rem;
                height: auto;

                @include respond-up(middle){
                    width: 5.43169rem;
                }

                @include respond-up(large){
                    width: 4.5rem;
                }

                &:hover{
                    transform: scale(1.1);
                    cursor: pointer;
                    transition: transform 0.3s;
                }
            }
        }

        .video-half{
            display: flex;
            gap: 3rem;

            flex-wrap: nowrap;
            align-content: flex-start;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            @include respond-up(large){
                flex-direction: row;
                gap: 8rem;
            }


            &__item{
                &:first-child{
                    width: 100%;
                    @include respond-up(large){
                        width: 80%;
                    }
                }
                width: 100%;
                @include respond-up(large){
                    width: 120%;
                }
            }
        }

        .video-logo-list{
            display: flex;
            flex-direction: column;
            gap: 4rem;
            margin: 0px auto;
            width: 100%;


            @include respond-up(medium){
                width: 60%;
            }

            @include respond-up(large){
                width: 100%;
            }

            &__item{

            }
        }
    }

    .benefits{

        display: flex;
        flex-direction: column;
        gap: 3.75rem;


        .benefits-half{
            display: grid;
            align-items: stretch;
            justify-items: stretch;
            justify-content: space-between;
            grid-template-columns: 1fr;
            align-content: stretch;
            gap: 0rem;

            @include respond-up(large){
                grid-template-columns: 1fr 1fr;
                gap: 2rem;
            }
        }

        .benefits-image{
            text-align: center;
            img{

                width: 100%;
                max-height: none;

                @include respond-up(medium){
                    width: 80%;
                    max-height: none;
                }

                @include respond-up(large){
                    width: auto;
                    max-height: 380px;
                }


            }

        }

        .benefits-text{


            text-align: justify;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            margin-bottom: 3.12rem;

            @include respond-up(medium){
                font-size: 1.5rem;
            }

            p{
                margin: 1rem 0;
            }
        }

        .benefits-list{


            display: none;
            @include respond-up(large){
                display: block;
            }

            &__item{
                text-align: left;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 3rem 0;
                position: relative;

                @include respond-up(medium){
                    font-size: 1.5rem;
                }

                &:last-child{
                    margin-bottom: 0;
                }

                &.orange{
                    &::before{
                        background-color: #F8AF04;
                    }
                }

                &.red{
                    &::before{
                        background-color: #E6372D;
                    }
                }

                &.blue{
                    &::before{
                        background-color: #081D4A;
                    }
                }
                &.silver{
                    &::before{
                        background-color: #B2B3B3;
                    }
                }
                &.sky{
                    &::before{
                        background-color: #66C3D0;
                    }
                }
                &.green{
                    &::before{
                        background-color: #009846;
                    }
                }
                &.purple{
                    &::before{
                        background-color: #855FB6;
                    }
                }

                &::before{
                    content: '';
                    display: block;
                    clear: both;
                    height: 4px;
                    background-color: red;
                    width: 80px;
                    position: absolute;
                    top: -12px;
                    transform: matrix(1, 0, -1, 1, 0, 0);
                }
            }
        }

        .benefits-list-slider{

            @include respond-up(large){
                display: none;
            }

            &__item{
                text-align: left;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 3rem 0;
                position: relative;
                padding: 0rem 0.5rem;

                @include respond-up(medium){
                    font-size: 1.5rem;
                }

                &:last-child{
                    margin-bottom: 0;
                }

                &.orange{
                    &::before{
                        background-color: #F8AF04;
                    }
                }

                &.red{
                    &::before{
                        background-color: #E6372D;
                    }
                }

                &.blue{
                    &::before{
                        background-color: #081D4A;
                    }
                }
                &.silver{
                    &::before{
                        background-color: #B2B3B3;
                    }
                }
                &.sky{
                    &::before{
                        background-color: #66C3D0;
                    }
                }
                &.green{
                    &::before{
                        background-color: #009846;
                    }
                }
                &.purple{
                    &::before{
                        background-color: #855FB6;
                    }
                }

                &::before{
                    content: '';
                    display: block;
                    clear: both;
                    height: 4px;
                    background-color: red;
                    width: 80px;
                    position: absolute;
                    top: -12px;
                    transform: matrix(1, 0, -1, 1, 0, 0);
                }
            }
        }
    }

    .contacts-page{
        .map-container{
            padding-top: 0px;
            .block-map{
                position: relative;
                .map{
                    height: 34rem;
                }

            }
        }



        .contacts-main-container{

            padding: 40px 0px;
            background: #f3f3f3;

            @include respond-up(medium){
                background: transparent;
            }

            @include respond-up(large){
                padding: 0px;
            }

            .contacts-block {
        
        
                display: grid;
                grid-gap: 20px;
                grid-template-columns: repeat(auto-fit,  minmax(340px, 1fr));
                &.flex-2 {
                display: block;
                @media(min-width:784px) {
                    display: grid;
                    grid-gap: 20px;
                    grid-template-columns: repeat(2, 1fr);
                }
                }
            
                &.en{
                    margin-top: 0rem;

                    @include respond-up(large){
                        margin-top: 3rem;
                    }

                    padding: 0px 0px;
                }
                @include respond-down(small) {
                grid-template-columns: 1fr;
                margin: 60px 0 0;
            
                &.en{
                    margin: 0px;
                }
                }
            
                .item {
            
                .item-wrapper{
                    box-shadow: 0 0 0 1px rgba(245, 177, 66, .36);
                    height: 100%;
                    background: #ffffff;
                }
            
            
                .title {
                    font-size: 20px;
                    font-weight: $bold;
                    text-align: center;
                    margin-bottom: 20px;
                }
            
                .wrapper-contact {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 10px 0px 10px;
            
                    @include respond-down(medium) {
                    padding: 0px 20px 0px 20px;
                    }
            
                    @include respond(small) {
                    padding: 10px 20px 0px 20px;
                    }
                    .contact-line {
                    display: flex;
                    margin-bottom: 15px;
                    .icon {
                        .svg-icon {
                        width: 21px;
                        height: 20px;
                        }
                    }
                    .value {
                        margin-left: 10px;
                        font-size: 16px;
                    }
                    }
                }
            
                &_large {
                    grid-row: span 2;


                    margin: -10px -12px 0 -12px;

                    @include respond-up(medium) {
                        margin: -20px -30px 0px 0px;
                    }


                    .name_duplicate {
                    background: $white;
                    padding: 0 5px;
                    }
                    .item-wrapper {
                    display: flex;
                    flex-direction: column;
                    }
                    .wrapper-contact {
                    flex: 1 1;
                    }
                }
                }
            }
            


        }
    }


    .table-mobile-container{

        width: 100%;
        height: 100%;

        &__wrapper{
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.9);

            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 2rem;
        }
        &__icon{
            svg{
                width: 8rem;
                height: auto;
            }
        }
        &__title{
            font-size: 18px;
            text-align: center;
        }
    }


    .table-container{
        margin-top: 4.56rem;
        width: 100%;
        position: relative;

        overflow-x: hidden;

        &__mobile{
            position: absolute;
            width: 100%;
            height: 100%;

            @include respond-up(large){
                display: none;
            }
        }



    }

    .table{

        width: 100%;
        border-collapse: collapse;
        border: 1px solid #000;


        tr{
            border: 1px solid #000;
            td{

                &:first-child{
                    min-width: auto;
                }

                border: 1px solid #000;
                padding: 0.4rem;
                text-align: center;
                vertical-align: middle;
            }
        }

        thead{
            background: #2C2859;
            color: #fff;
            font-size: 13px;
        }
    }

    .specification-logos{
        display: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;

        @include respond-up(large){
            display: flex;
        }

        &__item{

            max-width: 200px;
            margin: 0rem;
            img{
                max-height: 3rem;
                height: 40px;
            }
        }
    }
}

.magnets_landing_header {
    background-color: #fff;

    &__no_padding{
        padding: 0px;
    }

    &__fixed{
        position: fixed;
        width: 100%;
        padding: 0;
        margin: 0px;
        z-index: 100;
        background-color: #fff;
    }

    &__no_padding{
        padding: 0px;

        @include respond-up(large){
            padding-left: 6px;
            padding-right: 6px;
        }
    }

    &__no_padding2{
        padding: 0px;

        @include respond-up(large){

            padding-left: 43.5px;
            padding-right: 43.5px;
        }
    }

    &__wrapper{
        position: fixed;

        width: 100%;
        margin: 0 auto;
        background-color: #fff;
        z-index: 4;

        @include respond-up(medium){
            width: auto;
            margin: auto;
            position: relative;
        }


        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        padding: 0rem;

        @include respond-up(large){
            padding: 2rem 0rem;
        }

    }


    &__burger_wrap{
        display: flex;
        flex-direction: row;
        gap: 1.44rem;

        @include respond-up(large){
            display: none;
        }
    }

    &__logo{
        width: 180px;
        max-height: 44px;
        margin-left: 1rem;
        @include respond-up(large){
            margin: 0px;
        }
    }



    &__contacts{
        display: none;
        flex-direction: column;
        gap: 0.62rem;

        @include respond-up(large) {
            display: flex;
        }
    }

    &__whatsapp{
        display: flex;


        .icon_link__icon{
            width: 1.875rem;
            height: 1.875rem;
        }

        .icon_link__text{
            display: none;

            @include respond-up(medium) {
                display: inline;

                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        @include respond-up(large) {
            display: none;
        }
    }

    &__burger{
        background-color: #f5b142;
        width: 4.875rem;
        height: 4.6875rem;

        @include respond-up(middle){
            width: 3.875rem;
            height: 4.6875rem;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 4px;

        @include respond-up(large){
            display: none;
        }

        &__line{
            width: 25px;
            height: 3px;
            background-color: #fff;
        }
    }

    &__mobile_menu{
        display: none;
        flex-direction: row;
        gap: 1rem;
        align-content: center;
        justify-content: center;
        align-items: center;

        @include respond-up(large) {
            display: none;
        }

        .icon{
            width: 2rem;
            height: 2rem;
        }
    }

    &__menu{



        display: none;
        position: absolute;
        top: 75px;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 0;
        border-top: 3px solid #f5b142;

        &.visible{
            display: flex;
        }



        @include respond-up(large) {
            display: flex;
            position: relative;
            top: 0;
            width: auto;
            border: none;

            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            gap: 3rem;
        }

        li{
            width: 100%;
            text-align: center;
            background: white;
            min-height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid silver;

            &.last{
                padding: 20px 0px;

                @include respond-up(large){
                    display: none;
                }
                a{
                    .title{
                        padding: 0px 40px;
                    }
                }

            }

            @include respond-up(large) {
                width: auto;
                text-align: left;
                background: none;
                min-height: auto;
                display: block;
                border-bottom: none;

            }
        }


        a{
            color: #001E4D;

            font-size: 0.93rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
    }


}

.icon_link{
    display: flex;
    flex-direction: row;
    align-content: start;
    justify-content: center;

    &__link{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        gap: 1.31rem;
    }

    &__icon{
        width: 1.25rem;
        height: 1.25rem;
    }

    &__text{
        color: #001E4D;

        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
}